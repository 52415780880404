<template>
  <div style="padding: 50px 100px;">
    <el-card class="p-3">
      <el-table :data="tableData" style="width: 100%" header-align="left">
        <el-table-column :label="v" v-for="(v, k, i) in reviewHead" :key="i"
          :render-header="$renderHeader" show-overflow-tooltip align="center" header-align="center" :width="k=='id'?55:''">
          <template slot-scope="scope">
            <span>{{scope.row[k]?scope.row[k]:'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="judge" label="Judge" width="150" show-overflow-tooltip align="center" header-align="center"></el-table-column>
        <el-table-column prop="status" label="Status" fixed="right" width="150" align="center" header-align="center">
          <template slot-scope="scope">
            <span :style="{ color: scope.row.status ? '#49F0D3' : '#FF9B8A' }">{{ scope.row.status | filterStatus
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Action" fixed="right" width="150" align="center" header-align="center">
          <template slot-scope="scope">
            <div class="action-icon">
              <span @click="getElectDetail(scope.row)">Check</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottomof">
        <el-pagination :current-page="page" background layout="prev, pager, next" :total="total" :page-size="size"
          @current-change="handleCurrentChange" hide-on-single-page></el-pagination>
      </div>
    </el-card>

    <!-- 草稿弹框 -->
    <el-dialog :visible.sync="checkDetail" title="Application Details" width="65%">
      <div style="background: #f5fbff; padding: 10px 5px">
        Event:
        <span style="color: #50b5ff; font-size: 20px">{{ eventName }}</span>
      </div>
      <div class="d-flex">
        <span>Questionnaire Title: </span>
        <h4>{{questionnaire}}</h4>
      </div>
      <section v-for="(model, ind) in detailQuestList" :key="ind" class="modelList">
        <div class="modelName">
          <h3>Section{{ ind + 1 }}:&nbsp;&nbsp;{{ model.classification }}</h3>
          <span>({{ model.score }}point)</span>
        </div>
        <div v-for="(que, i) in model.topic" :key="i" class="questionList">
          <div class="d-flex mb-2">
            <span style="color: #f00" v-if="que.is_required">*</span>
            <h5>Question{{ i + 1 }}. &nbsp;&nbsp;{{ que.topic }}</h5>
            <span class="ml-2" v-if="que.type == 3&&que.size" style="color: #999;">(limit:
              {{que.size}})</span>
          </div>
          <div class="answerContent">
            <div class="answer">
              <span>Answer</span>
              <!-- 不定项选择题 -->
              <div v-if="que.type == 1" class="d-flex flex-wrap" style="width: 90%;">
                <div class="douInt" v-for="(i, index) of que.option" :key="index">
                  <p :class="{ outlineBlue: que.user_answer.includes(String(index)) }">
                    {{ numList[index] }}
                  </p>
                  <p :title="i">{{ i }}</p>
                </div>
              </div>

              <!-- 会员 -->
              <div v-if="que.type == 2" class="d-flex flex-wrap">
                <el-tag v-for="(item, index) in que.user_answer" :key="index" type="info"
                  class="cursor-pointer mr-2 mb-3">{{item.nick_name}}</el-tag>
              </div>

              <!-- 填空 -->
              <div v-if="que.type == 3">
                <p class="m-0">
                  {{ que.user_answer }}
                </p>
              </div>

            </div>

            <!-- 图片 -->
            <div v-show="que.picture" class="picture">
              <span>Upload Picture</span>
              <div v-if="que.image.length">
                <ul>
                  <li v-for="(img, index) in que.image" :key="index">
                    <img :src="img" alt="" />
                  </li>
                </ul>
              </div>
            </div>

            <!-- 链接 -->
            <div v-show="que.url" class="video">
              <span>Video Connection</span>
              <div v-show="que.link.length || que.link">
                <p class="m-0">
                  <a style="text-decoration: underline;" target="_blank" :href="que.link">
                    {{ que.link }}
                  </a>
                </p>
              </div>
            </div>

          </div>
        </div>

        <div class="gradeInput">
          <span>Grade</span>
          <el-input style="width: 250px" v-model="model.user_score" type="number" :disabled="!!isElect"
            onblur="value=value.replace(/[^\d]/g,'')"
            @input="model.user_score=model.user_score>=model.score?model.score:model.user_score<0?0:model.user_score">
          </el-input>
        </div>
        <hr style="border: 1px solid #f5f5f5" />
      </section>

      <div class="total-grade">
        <div>
          <span class="title">Total score</span>
          <span class="score">{{ computedTotalScore }}</span>
        </div>
        <el-button type="primary" @click="reviewSubmit" v-if="!isElect">Submit</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        total: 0,
        size: 10,
        page: 1,

        tableData: [],
        eventName: "",

        checkDetail: false,
        detailQuestList: [],
        detailId: 0,
        isElect: "",

        // 判断是否有权限评审
        reviewHead: {},
        isElect: 0,
        questionnaire: "",
        numList: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
      };
    },

    created() {
      this.agent = this.$route.query.agent
      this.programAgentScoreList()
      console.log(this.agent);
    },

    filters: {
      filterStatus(val) {
        return val ? "Finished" : "Unfinish";
      },
      filterNone(val) {
        return val ? val : "-";
      },
      filterNone(val) {
        return val ? val : "-";
      },
      scoreFilter(val) {
        return val ? val : 0;
      },
      filterJudge(val) {
        console.log(111, val);
        var newArr = [];
        val.forEach(item => {
          newArr.push(item.real_name);
        });
        return newArr.length ? newArr.join(",") : [];
      }
    },
    computed: {
      computedTotalScore() {
        let totalList = this.detailQuestList;
        let total = totalList.reduce((sum, e) => sum + Number(e.user_score), 0);
        return total;
      }
    },

    methods: {
      getChangePageSize(pageSize) {
        this.size = pageSize;
        this.page = 1;
        this.programAgentScoreList();
      },

      handleCurrentChange(item) {
        this.page = item;
        this.programAgentScoreList();
      },

      programAgentScoreList() {
        this.$http.programAgentScoreList({ agent: this.agent }).then(res => {
          if (res.status == 200) {
            this.tableData = res.data.result.data;
            this.reviewHead = res.data.row
            this.total = res.data.result.total;
          }
        })
      },

      // 获取待评审列表详情
      getElectDetail(row) {
        this.detailId = row.score_id;
        this.$http.getUserProgramQuestionnaireInfo({ apply_id: row.id, score_id: row.score_id })
          .then(res => {
            if (res.status == 200) {
              this.checkDetail = true;
              this.eventName = res.data.event;
              this.questionnaire = res.data.questionnaire
              this.detailQuestList = res.data.all_topic;
              this.detailQuestList.forEach(v => {
                v.user_score = Number(v.user_score)
                v.score = Number(v.score)
                v.topic.forEach(ele => {
                  if (ele.type == 2) {
                    let user_answer = ele.user_answer
                    ele.user_answer = user_answer.map((item, i) => {
                      return { user_id: item, nick_name: ele.users[i] }
                    })
                  }
                })
              })
              this.isElect = res.data.score_status;
            }
          });
      },

      // 打分评审
      reviewSubmit() {
        let scoreArr = [];
        let totalList = this.detailQuestList;
        let result = totalList.filter(item => item.user_score == null);
        if (result.length) {
          this.$message.warning("There are questions not scored!");
        } else {
          totalList.forEach(item => {
            scoreArr.push(Number(item.user_score));
          });
          if (this.computedTotalScore <= 100) {
            this.$http.programJudgeScore({
              score_id: this.detailId,
              score_info: scoreArr,
            }).then(res => {
              this.checkDetail = false;
              this.programAgentScoreList();
            });
          } else {
            this.$message("The section score exceeds the maximum!");
          }
        }
      }
    }
  };
</script>
<style>
  .optionsEvent {
    width: 60vh !important;
  }
</style>
<style scoped lang="scss">
  .title {
    display: flex;
    justify-content: space-between;

    /deep/ .vs-con-dropdown {
      width: 130px;
    }

    .search_in {
      width: 200px;
    }
  }

  .bottomof {
    width: 100%;
    text-align: right;
    margin-top: 20px;
  }

  .outlineBlue {
    background: #50b5ff !important;
    color: #fff;
  }

  .outlineRed {
    background: #f00 !important;
    color: #fff;
  }

  .douInt {
    height: 50px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    flex-wrap: nowrap;
    width: 400px;

    p:first-child {
      width: 50px;
      height: 50px;
      padding: 14px;
      border: 1px solid #e6e6e6;
      border-right: none;
      background: #fff;
      text-align: center;
      cursor: pointer;
      border-end-start-radius: 5px;
      border-top-left-radius: 5px;
    }

    :last-child {
      width: 350px;
      height: 50px;
      padding: 14px 0;
      padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid #e6e6e6;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .modelList {
    margin-top: 20px;

    >.modelName {
      color: #333;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;

      >h3 {
        font-size: 18px;
      }
    }

    >.questionList {
      margin-bottom: 20px;

      >h3 {
        font-size: 14px;
        margin-bottom: 10px;
      }

      >.answerContent {
        background-color: #f2f7fb;
        border-radius: 8px;
        padding: 20px;

        >div {
          display: flex;
          margin-bottom: 20px;

          >span {
            display: block;
            width: 165px;
          }

          >div {
            width: calc(100 - 165px);

            >ul {
              display: flex;

              li {
                width: 90px;
                height: 90px;
                border-radius: 5px;
                overflow: hidden;
                margin-right: 10px;

                >img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }

    >.gradeInput {
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-bottom: 20px;

      >span {
        display: block;
        width: 165px;
      }
    }
  }

  /**
旧的样式
*/
  .questionDetail-list {
    >li {
      margin-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #e6e6e6;

      h3.index {
        color: #333;
      }

      >p.question-content {
        padding: 20px;
        border-radius: 5px;
        background-color: #f2f7fb;
        border: 1px solid #e8f1f7;
        margin-top: 20px;
        color: #666;
      }

      >div.anwers-img {
        margin-top: 30px;

        display: flex;
        align-items: center;

        >h3 {
          font-size: 14px;
          color: #333;
          width: 170px;
        }

        >ul {
          display: flex;

          li {
            width: 90px;
            height: 90px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 10px;

            >img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      >div.answer-link {
        display: flex;
        align-items: center;
        margin-top: 20px;

        >h3 {
          color: #333;
          font-size: 14px;
          width: 170px;
        }

        >p {
          padding: 12px;
          border-radius: 5px;
          background-color: #f2f7fb;
          border: 1px solid #e8f1f7;

          color: #666;
        }
      }

      >div.question-grade {
        display: flex;
        align-items: center;
        margin-top: 20px;

        >h3 {
          color: #333;
          font-size: 14px;
          width: 170px;
        }
      }
    }
  }

  .total-grade {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    >div {
      display: flex;
      align-items: center;

      >span.title {
        margin-right: 20px;
        color: #333;
      }

      >span.score {
        font-size: 20px;
        color: #333;
      }
    }
  }

  .action-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    >i {
      font-size: 20px;
      cursor: pointer;
      margin-right: 10px;
    }

    >span {
      cursor: pointer;
      color: #50b5ff;
      font-size: 16px;
    }
  }

  .member-list {
    >span {
      display: inline-block;
      margin-bottom: 5px;
      color: #53b6ff;
      background: #fff;
      padding: 5px 10px;
      border-radius: 4px;
      margin-right: 5px;
      border: 1px solid #f5f5f5;
      cursor: pointer;
    }
  }
</style>